<template>
    <v-container
        fluid
        grid-list-md
        mt-5>
        <v-layout
            row
            wrap>
            <v-flex
                flex
                xs12>
                <v-card class="wrap-content gray-icons">
                    <v-toolbar color="white">
                        <v-toolbar-title class="title primary--text pb-1">
                            Agency - {{ agency.name }}
                        </v-toolbar-title>
                        <v-spacer />
                        <v-tooltip
                            v-model="deleteAgency"
                            top>
                            <v-btn
                                slot="activator"
                                icon
                                @click="deleteAgencyModal = true">
                                <v-icon>delete</v-icon>
                            </v-btn>
                            <span>Delete Agency</span>
                        </v-tooltip>
                        <v-tooltip
                            v-model="editAgency"
                            top>
                            <v-btn
                                slot="activator"
                                icon
                                @click="editAgencyInputs = true">
                                <v-icon>edit</v-icon>
                            </v-btn>
                            <span>Edit Agency</span>
                        </v-tooltip>
                    </v-toolbar>
                    <v-dialog v-model="deleteAgencyModal">
                        <v-card>
                            <v-card-title>
                                <div class="headline">
                                    Delete {{ agency.name }}
                                </div>
                            </v-card-title>
                            <v-card-text>Are you sure you want to delete {{ agency.name }}?</v-card-text>
                            <v-card-actions>
                                <v-spacer />
                                <v-btn
                                    color="indigo darken-4"
                                    @click="deleteAgencyModal = false">
                                    Cancel <v-icon
                                        right
                                        dark>
                                        cancel
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    color="indigo darken-4"
                                    @click="removeAgency()">
                                    Delete <v-icon
                                        right
                                        dark>
                                        delete
                                    </v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-card-text v-if="editAgencyInputs">
                        <v-container
                            fluid
                            grid-list-md>
                            <v-form
                                ref="form"
                                v-model="valid">
                                <v-layout row>
                                    <v-flex xs12>
                                        <v-text-field
                                            v-model="agency.name"
                                            :rules="nameRules"
                                            name="name"
                                            label="Agency Name"
                                            prepend-icon="business"
                                            required />
                                    </v-flex>
                                </v-layout>
                                <v-layout row>
                                    <v-flex xs12>
                                        <v-text-field
                                            v-model="agency.url"
                                            name="url"
                                            label="Homepage URL"
                                            prepend-icon="home"
                                            required />
                                    </v-flex>
                                </v-layout>
                                <v-layout row>
                                    <v-flex xs12>
                                        <v-text-field
                                            v-model="agency.leads_from_email"
                                            :rules="emailRules"
                                            name="email"
                                            label="Leads From Email"
                                            prepend-icon="home"
                                            required />
                                    </v-flex>
                                </v-layout>
                                <v-btn
                                    :disabled="!valid"
                                    @click="submit()">
                                    submit
                                </v-btn>
                                <v-btn @click="editAgencyInputs = false">
                                    cancel
                                </v-btn>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-list v-if="!editAgencyInputs">
                        <v-list-tile
                            avatar
                            @click.prevent>
                            <v-list-tile-avatar>
                                <v-icon>person</v-icon>
                            </v-list-tile-avatar>
                            <v-list-tile-content>
                                <v-list-tile-title>ID</v-list-tile-title>
                                <v-list-tile-sub-title>{{ agency.id }}</v-list-tile-sub-title>
                            </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile
                            avatar
                            @click.prevent>
                            <v-list-tile-avatar>
                                <v-icon>person</v-icon>
                            </v-list-tile-avatar>
                            <v-list-tile-content>
                                <v-list-tile-title>Name</v-list-tile-title>
                                <v-list-tile-sub-title>{{ agency.name }}</v-list-tile-sub-title>
                            </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile
                            avatar
                            @click.prevent>
                            <v-list-tile-avatar>
                                <v-icon>email</v-icon>
                            </v-list-tile-avatar>
                            <v-list-tile-content>
                                <v-list-tile-title>Leads From Email</v-list-tile-title>
                                <v-list-tile-sub-title>{{ agency.leads_from_email }}</v-list-tile-sub-title>
                            </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile
                            avatar
                            @click.prevent>
                            <v-list-tile-avatar>
                                <v-icon>business</v-icon>
                            </v-list-tile-avatar>
                            <v-list-tile-content>
                                <v-list-tile-title>Homepage URL</v-list-tile-title>
                                <v-list-tile-sub-title>{{ agency.url }}</v-list-tile-sub-title>
                            </v-list-tile-content>
                        </v-list-tile>
                    </v-list>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import HTTP from '../../http';
    import ApiQuery from '../../api-query';

    export default {
        title: 'Agency',
        data() {
            return {
                valid: false,
                nameRules: [
                    v => !!v || 'Name is required',
                ],
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
                ],
                agency: [],
                errors: null,
                deleteAgency: false,
                deleteAgencyModal: false,
                editAgency: false,
                editAgencyInputs: false
            };
        },
        created() {


            this.getAgencyData();
        },
        methods: {
            submit() {
                (new HTTP)
                    .put(
                        '/agencies/' + this.$route.params.id,
                        {
                            name: this.agency.name,
                            url: this.agency.url,
                            leads_from_email: this.agency.leads_from_email,
                        }
                    )
                    .then(() => {
                        this.$flash('Agency updated successfully!', 'green');
                        this.editAgencyInputs = false;
                        this.refreshAgency();
                        this.clearForm();
                    })
                    .catch(e => {
                        this.$flash('Error!', 'red');
                        console.log(e);
                    });
            },
            clearForm() {
                this.$refs.form.reset();
            },
            getAgencyData() {
                this.agency = [];

                this.$apiRepository
                    .getAgency(this.$route.params.id)
                    .then(response => {
                        this.agency = response.data.data;
                    })
                    .catch(e => {
                        console.log(e);
                    });
            },
            removeAgency() {
                (new ApiQuery('agencies/' + this.$route.params.id))
                    .delete()
                    .then(response => {
                        this.response = response.data.data;
                        this.deleteAgencyModal = false;
                        this.$router.push('/agencies');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            },
            refreshAgency() {
                this.getAgencyData();
            },
        }
    };
</script>
